<template>
    <section>
      <div class="w-full bg-white shadow-md rounded-md p-4">
        <div class="w-full lg:flex gap-4">
            <div class="w-full lg:w-4/12">
                <div>
                    <p class="lg:text-xs font-bold mb-2">Comentarios</p>
                    <div class="w-full">
                      <p v-if="recepcion.estado === 'EN STOCK'">{{ recepcion.Comentarios }}</p>
                      <Textarea v-else class="w-full" v-model="comentarios" rows="5" cols="30" />
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-5/12 mt-5 lg:flex gap-10">
                <div>
                    <p class="lg:text-xs font-bold mb-2">Responsable de envio:</p>
                    <p>{{ recepcion.enviadoPor }}</p>
                </div>
                <div>
                    <p class="lg:text-xs font-bold mb-2">Responsable Entrega:</p>
                    <p>{{ recepcion.recibidoPor }}</p>
                </div>
                <div>
                    <p class="lg:text-xs font-bold mb-2">Responsable Recepción:</p>
                    <p>{{ recepcion.resposableRecepcion }}</p>
                </div>
            </div>
            <div class="w-full lg:w-3/12 mt-5 px-4">
              <Button v-if="recepcion.estado === 'POR RECEPCIONAR'" @click="openModal" class="p-button-success w-full" label="INGRESAR INVENTARIO" />
            </div>
        </div>
      </div>
      <Dialog
        v-model:visible="displayModal"
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal="true"
        >
        <template #header>
          <div class="w-full flex justify-center">
            <img class="mr-2" style="width: 1rem;" src="../../../../../../../../../assets/images/hand-money-blue.svg" alt="">
            <h3 class="font-bold text-blue-800">RECEPCIÓN A BODEGA</h3>
          </div>
        </template>
        <div class="w-full">
          <div class="lg:flex justify-between">
            <div class="grid grid-cols-3 gap-4">
              <div>
                <span class="text-xs">ID Recep</span>
                <p class="text-sm font-bold">
                  {{ recepcion.id }}
                </p>
              </div>
              <div>
                <span class="text-xs">N° Guía</span>
                <p class="text-sm font-bold">
                  {{ recepcion.numGuia }}
                </p>
              </div>
              <div>
                <span class="text-xs">N° Oficio</span>
                <p class="text-sm font-bold">
                  {{ recepcion.numOficio }}
                </p>
              </div>
            </div>
            <div class="flex justify-end gap-4">
              <div class="mr-4">
                <span class="text-xs">Bodega</span>
                <p class="text-sm font-bold">
                  {{ recepcion.bodDestino }} - {{ recepcion.bodDestinoName }}
                </p>
              </div>
              <div>
                <span class="text-xs">Fecha de recepción</span>
                <p class="text-sm font-bold">
                  {{ dayjs().format('YYYY/MM/DD') }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex gap-2 mt-4 border-b">
            <div v-for="(option, i) in optionsModalToCreate" :key="i">
              <button class="mx-2" :class="option.state ? 'text-sm border-b-4 border-blue-600 font-bold' : 'text-xs'" @click="activarOption(option)">
                {{ option.name }}
              </button>
            </div>
          </div>
          <div class="my-4" v-if="selectedOptionModal.id === 1">
            <DataTable
              :value="recepcionDetails.filter(a => !a.adicional)"
              responsiveLayout="scroll"
              headerClass="bg-white"
              class="lg:text-xs pb-1 px-1"
              dataKey="id"
            >
              <template #empty>
                No existen recepciones registradas
              </template>
              <Column field="itemCode" header="N° Articulo" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
                <template #body="{data}">
                  <div class="w-full h-full">
                    <p class="lg:text-xs flex items-center">
                      {{ data.itemCode}}
                    </p>
                  </div>
                </template>
              </Column>
              <Column field="itemName" header="Medicamento" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
                <template #body="{data}">
                  <div class="w-full h-full">
                    <p class="lg:text-xs flex items-center">
                      {{ data.itemName}}
                    </p>
                  </div>
                </template>
              </Column>
              <Column field="lote" header="Lote" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
                <template #body="{data}">
                  <div class="w-full h-full">
                    <p class="lg:text-xs flex items-center">
                      {{ data.lote}}
                    </p>
                  </div>
                </template>
              </Column>
              <Column field="fecVen" header="Fecha de venc." headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
                <template #body="{data}">
                  <div class="w-full h-full">
                    <p class="lg:text-xs flex items-center">
                      {{ data.fecVen}}
                    </p>
                  </div>
                </template>
              </Column>
              <Column field="cantidad" header="Cant Env." headerClass="lg:text-xs" class="my-1 py-1 shadow-sm" style="min-width:4rem; color: #4A4A4A;">
                <template #body="{data}">
                  <div class="w-full h-full">
                    <p class="lg:text-xs flex items-center">
                      {{ data.cantidad}}
                    </p>
                  </div>
                </template>
              </Column>
              <Column field="cantRec" header="Cant Recep" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm" style="max-width:5rem; color: #4A4A4A;">
                <template #body="{data}">
                  <div class="flex items-center">
                    <div class="w-full h-full">
                      <p class="lg:text-xs flex items-center">
                        {{ data.cantRec}}
                      </p>
                    </div>
                    <i
                      v-if="data.novedades.length"
                      style="margin-top: 0.1rem;"
                      class="pi pi-info-circle ml-2 lg:text-xs font-bold text-red-600">
                    </i>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="my-4" v-if="selectedOptionModal.id === 2">
            <Accordion v-if="medicamentosConNovedades.length" class="shadow-md gap-4" :activeIndex="0">
              <AccordionTab v-for="(medicamento, k) in medicamentosConNovedades" :key="k">
                <template #header>
                  <div class="flex justify-between w-full">
                    <div class="flex text-xs">
                      <span class="font-bold mr-4">{{medicamento.itemCode}}</span>
                      <span class="font-bold mr-4">{{medicamento.itemName}}</span>
                    </div>
                    <div class="flex items-center text-xs">
                      <div class="flex" v-if="medicamento.adicional">
                        <p class="mr-2">
                          CANTIDAD: {{medicamento.cantidad}}
                        </p>
                        <div class="flex gap-2">
                          <svg style="width:24px;height:24px; margin-top: -4px;" viewBox="0 0 24 24">
                              <path fill="#085B9E" d="M3 16H10V14H3M18 14V10H16V14H12V16H16V20H18V16H22V14M14 6H3V8H14M14 10H3V12H14V10Z" />
                          </svg>
                          <i class="pi pi-exclamation-circle text-red-600"></i>
                        </div>
                      </div>
                      <div class="flex" v-else>
                        <p class="mr-2">
                          CANTIDAD: {{medicamento.cantRec}}
                        </p>
                        <i class="pi pi-exclamation-circle text-red-600"></i>
                      </div>
                    </div>
                  </div>
                </template>
                {{medicamentosConNovedades.novedades}}
                <DataTable
                  :value="medicamento.novedades"
                  responsiveLayout="scroll"
                  headerClass="bg-white"
                >
                  <template #empty>
                    No existen novedades registradas
                  </template>
                  <Column field="idNovedad" header="Novedad" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
                    <template #body="{data}">
                      <p class="text-xs flex items-center">
                        {{data.idNovedad}}
                      </p>
                    </template>
                  </Column>
                  <Column field="cantidad" header="Cantidad" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
                    <template #body="{data}">
                      <p class="text-xs flex items-center">
                        {{data.cantidad}}
                      </p>
                    </template>
                  </Column>
                  <Column header="Observación"
                    headerClass="text-xs"
                    dataType="date"
                    style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
                    class="shadow-sm"
                    field="comentario"
                  >
                    <template class="text-xs" #body="{data}">
                      <p class="text-xs flex items-center">
                        {{ data.comentario }}
                      </p>
                    </template>
                  </Column>
                </DataTable>
              </AccordionTab>
            </Accordion>
            <div v-else>
              <p>
                Esta recepción no cuenta con novedades
              </p>
            </div>
          </div>
          <div class="w-full mt-4 flex justify-between text-xs items-center">
            <div>
              <button @click="changeOptionViewModal(1)" v-if="selectedOptionModal.id === 2" class="font-bold">
                <i class="pi pi-arrow-left mr-2 lg:text-xs font-bold"></i>
                ATRAS
              </button>
            </div>
            <div>
              <button @click="changeOptionViewModal(2)" v-if="selectedOptionModal.id === 1" class="font-bold">
                SIGUIENTE
                <i class="pi pi-arrow-right ml-2 lg:text-xs font-bold"></i>
              </button>
            </div>
            <div v-if="selectedOptionModal.id === 2" class="flex gap-4">
              <Button
                label="CANCELAR"
                @click="closeModal(1)"
                class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
              <Button
                label="ACEPTAR"
                @click="closeModal(2)"
                class="bg-blue-600 hover:bg-gray-300 text-white-800 hover:text-white-800 border-0"/>
            </div>
          </div>
        </div>
      </Dialog>
    </section>
  </template>
  <script>
    import RecepcionStore from '../../../../../store/recepcion.store'
    import NovedadesTipoService from '../../../../../services/novedades-tipo.service'
    import RecepcionService from '../../../../../services/recepcion.service'
    import { ref, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { groupBy, sumBy } from 'lodash'
    import Swal from 'sweetalert2'
    import dayjs from 'dayjs'

    export default {
    name: 'ComponenteCommentsRecepcion',
    setup () {
      // Services
      const _NovedadesTipoService = ref(new NovedadesTipoService())
      const _RecepcionService = ref(new RecepcionService())
      const router = useRouter()
      // References
      const busqueda = ref(true)
      const tiposNovedades = ref([])
      const comentarios = ref('')
      const jsonToCreate = ref({})
      const validarInputTable = ref(false)
      const displayModal = ref(false)
      const medicamentos = ref([])
      const medicamentosConNovedades = ref([])
      const oficio = ref({})
      // const validationMdNovedad = ref(false)
      const selectedOptionModal = ref({
          name: 'INGRESO A LA BODEGA',
          state: true,
          id: 1
        })
      // Computed
      const recepcion = computed(() => RecepcionStore.getters._recepcion)
      const recepcionDetails = computed(() => RecepcionStore.getters._recepcionDetails)
      const novedades = computed(() => RecepcionStore.getters._novedades)

      const optionsModalToCreate = ref([
        {
          name: 'INGRESO A LA BODEGA',
          state: true,
          id: 1
        },
        {
          name: 'NOVEDADES',
          state: false,
          id: 2
        }
      ])
      // Methods
      const getTiposNovedades = () => {
        _NovedadesTipoService.value.get().then(({ data }) => {
          tiposNovedades.value = data
        })
      }
      const openModal = () => {
        if (recepcionDetails.value.some(e => !e.state)) {
          Swal.fire(
            'Advertencia',
            'Debe registrar todos los medicamentos',
            'warning'
          )
        } else {
          displayModal.value = true
          medicamentosConNovedades.value = recepcionDetails.value.filter(a => a.novedades.length)
          if (recepcionDetails.value.some(a => a.adicional === true)) {
            recepcionDetails.value.map(a => {
              if (a.adicional) {
                medicamentosConNovedades.value.push({
                  adicional: true,
                  itemCode: a.itemCode,
                  itemName: a.itemName,
                  lote: a.lote,
                  cantidad: a.cantidad,
                  novedades: [{
                    idNovedad: a.idNovedad,
                    comentario: a.comentario,
                    cantidad: a.cantidad
                  }]
                })
              }
            })
          }
        }
      }
      const closeModal = async (type) => {
        if (type === 2) {
          crearTransferencia()
        }
        displayModal.value = false
        medicamentosConNovedades.value = []
      }
      const activarOption = (option) => {
        selectedOptionModal.value = option
        optionsModalToCreate.value.map(a => {
          a.name === option.name ? a.state = true : a.state = false
        })
      }
      const changeOptionViewModal = (where) => {
        if (where === 1) {
          const id = selectedOptionModal.value.id - 1
          optionsModalToCreate.value.map(a => {
            a.id === id ? a.state = true : a.state = false
            a.id === id ? selectedOptionModal.value = a : console.log()
          })
        }
        if (where === 2) {
          const id = selectedOptionModal.value.id + 1
          optionsModalToCreate.value.map(a => {
            a.id === id ? a.state = true : a.state = false
            a.id === id ? selectedOptionModal.value = a : console.log()
          })
        }
      }
      const crearTransferencia = () => {
        if (comentarios.value) {
          if (recepcionDetails.value.some(a => !a.state)) {
            Swal.fire({
              title: 'Advertencia',
              icon: 'warning',
              text: 'Debe verificar la llegada de todos los medicamentos',
              confirmButtonText: 'Ok'
            })
          } else {
            const _groupBy = groupBy(recepcion.value.details, 'itemCode')
            const StockTransferLines = []
            let baseLine = 0
            for (const [key, item] of Object.entries(_groupBy)) {
              const BatchNumbers = item.map(a => {
                return {
                  BatchNumber: a.lote,
                  Quantity: a.cantidad,
                  BaseLineNumber: baseLine,
                  ExpiryDate: a.fecVen
                }
              })
              const StockTransferLinesBinAllocations = []
              let contador = 0
              for (const values of BatchNumbers) {
                StockTransferLinesBinAllocations.push(
                  {
                    BinAbsEntry: recepcion.value.fromAbsEntry,
                    Quantity: values.Quantity,
                    AllowNegativeQuantity: 'tNO',
                    SerialAndBatchNumbersBaseLine: contador,
                    BinActionType: 'batFromWarehouse',
                    BaseLineNumber: baseLine
                  },
                  {
                    BinAbsEntry: recepcion.value.absEntry,
                    Quantity: values.Quantity,
                    AllowNegativeQuantity: 'tNO',
                    SerialAndBatchNumbersBaseLine: contador,
                    BinActionType: 'batToWarehouse',
                    BaseLineNumber: baseLine
                  }
                )
                contador++
              }
              const data = {
                ItemCode: key,
                Quantity: sumBy(item, 'cantidad'),
                WarehouseCode: recepcion.value.bodDestino,
                FromWarehouseCode: recepcion.value.bodDestino,
                BatchNumbers: BatchNumbers,
                StockTransferLinesBinAllocations: StockTransferLinesBinAllocations
              }
              StockTransferLines.push(data)
              baseLine++
            }
            jsonToCreate.value = {
              escala: recepcion.value.escala,
              Comments: comentarios.value,
              Oficio: oficio.value,
              Novedades: novedades.value,
              StockTransferLines: StockTransferLines,
              U_PHR_Guia: recepcion.value.numGuia,
              BaseEntry: recepcion.value.docEntry
            }
            _RecepcionService.value.post(jsonToCreate.value).then(({ data }) => {
              Swal.fire({
                title: 'Exito',
                html: `<p>Recepcion ingresada al stock con exito</p><p>Se ha creado la transferencia con el número: <strong>${data.infoTransferSap.DocNum}</strong></p>`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push({ name: 'pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion' })
                }
              })
            }).catch((error) => {
              const { message, info } = error.response.data
              Swal.fire({
                title: 'Error',
                html: `
                  <p>${message}</p>
                  <p><strong>${info.join(', ')}</strong></p>
                `,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              })
            })
          }
        } else {
          Swal.fire({
            title: 'Advertencia',
            icon: 'warning',
            text: 'Los comentarios son obligatorios',
            confirmButtonText: 'Ok'
          })
        }
      }
      onMounted(() => {
        getTiposNovedades()
        if (recepcionDetails.value.length) {
          recepcionDetails.value.map(a => {
            if (a.adicional) {
              medicamentos.value = recepcionDetails.value
            } else {
              medicamentos.value = recepcionDetails.value.filter(a => !a.adicional)
            }
          })
        }
      })
      return {
        busqueda,
        recepcion,
        crearTransferencia,
        novedades,
        tiposNovedades,
        comentarios,
        validarInputTable,
        closeModal,
        displayModal,
        optionsModalToCreate,
        activarOption,
        selectedOptionModal,
        changeOptionViewModal,
        medicamentos,
        oficio,
        dayjs,
        recepcionDetails,
        medicamentosConNovedades,
        openModal
      }
    }
  }
  </script>

  <style scoped>
  </style>
