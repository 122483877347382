import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA
export default class NovedadesTipoService {
    get () {
        return http.get(`${baseUrl}/novedades-type/option-select`, {
            headers: {
                loading: false
            }
        })
    }

    getDetails () {
        return http.get(`${baseUrl}/novedades-type/novedades-detail`, {
            headers: {
                loading: false
            }
        })
    }
}
